import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

// Spaß mit Formularen https://reactjs.org/docs/forms.html

export default class Page extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      a: 0,
      b: 0,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.type === "number"
        ? parseInt(target.value)
        : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    alert(`Das Ergebnis lautet ${this.sum()}`)
  }

  sum() {
    return this.state.a + this.state.b
  }

  render() {
    return (
      <Layout title="Schulnummern in NRW">
        <SEO title="Suche der Schulnummer in NRW" />

        <p>Der Generator befindet sich noch in der Entwicklung...</p>

        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="field-a">Wert A:</label>
            <input
              id="field-a"
              aria-label="a"
              name="a"
              type="number"
              className="form-control"
              value={this.state.a}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="field-b">Wert B:</label>
            <input
              id="field-b"
              aria-label="b"
              name="b"
              type="number"
              className="form-control"
              value={this.state.b}
              onChange={this.handleInputChange}
            />
          </div>

          <button type="submit" className="btn btn-primary">
            Absenden
          </button>

          <div>
            <p>
              Die Formel wird lauten: {this.state.a} + {this.state.b} ={" "}
              {this.sum()}
            </p>
          </div>
        </form>
      </Layout>
    )
  }
}
